.fp-enabled body, html.fp-enabled {
    margin: 0;
    padding: 0;
    overflow: hidden;
    -webkit-tap-highlight-color: transparent;
}

.btn-circle { 
    width: 50px; 
    height: 50px; 
    padding: 7px 10px; 
    border-radius: 25px; 
    font-size: 10px; 
    text-align: center; 
}

.btn-main_country {
    background-color: #543790 !important;
}

.btn-circle:hover {
    -webkit-box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
    box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
}

.btn-circle:hover {
    -webkit-box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
    box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
}

.btn-circle:active {
    -webkit-box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
    box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
}

.btn-main_country:hover {
    background-color: #543790;
}

.btn-main_country:focus {
    background-color: #543790;
    border: none;
    box-shadow: none;
}

.btn-main_country:not(:disabled):not(.disabled):active:focus {
    box-shadow: none;
    background-color: #543790 !important;
}

.img-flag {
    width: 100%;
    height: 100%;
}

.dropdown-menu {
    min-width: 0px;
    border: none;
    background-color: inherit;
}

.dropdown-item {
    margin-bottom: 5px;
    background-color: #64489e;
}

.dropdown-item:hover {
    background-color: #64489e;
}

.fp-section {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fp-slide {
    float: left;
}

.fp-slide, .fp-slidesContainer {
    height: 100%;
    display: block;
}

.fp-slides {
    z-index: 1;
    height: 100%;
    overflow: hidden;
    position: relative;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out;
}

.fp-section.fp-table, .fp-slide.fp-table {
    display: table;
    table-layout: fixed;
    width: 100%
}

.fp-tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%
}

.fp-slidesContainer {
    float: left;
    position: relative;
}

.fp-controlArrow {
    -webkit-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -ms-user-select: none;
    position: absolute;
    z-index: 4;
    top: 50%;
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    margin-top: -38px;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.fp-controlArrow.fp-prev {
    left: 15px;
    width: 0;
    border-width: 38.5px 34px 38.5px 0;
    border-color: transparent #fff transparent transparent;
}

.fp-controlArrow.fp-next {
    right: 15px;
    border-width: 38.5px 0 38.5px 34px;
    border-color: transparent transparent transparent #fff;
}

.fp-scrollable {
    overflow: hidden;
    position: relative;
}

.fp-scroller {
    overflow: hidden;
}

.iScrollIndicator {
    border: 0!important;
}

.fp-notransition {
    -webkit-transition: none!important;
    transition: none!important;
}

#fp-nav {
    position: fixed;
    z-index: 100;
    margin-top: -32px;
    top: 50%;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
}

#fp-nav.fp-right {
    right: 17px;
}

#fp-nav.fp-left {
    left: 17px;
}

.fp-slidesNav {
    position: absolute;
    z-index: 4;
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0!important;
    right: 0;
    margin: 0 auto!important;
}

.fp-slidesNav.fp-bottom {
    bottom: 17px;
}

.fp-slidesNav.fp-top {
    top: 17px;
}

#fp-nav ul, .fp-slidesNav ul {
    margin: 0;
    padding: 0;
}

#fp-nav ul li, .fp-slidesNav ul li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position: relative;
}

.fp-slidesNav ul li {
    display: inline-block;
}

#fp-nav ul li a, .fp-slidesNav ul li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}

#fp-nav ul li a.active span, #fp-nav ul li:hover a.active span, .fp-slidesNav ul li a.active span, .fp-slidesNav ul li:hover a.active span {
    height: 12px;
    width: 12px;
    margin: -6px 0 0 -6px;
    border-radius: 100%
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    height: 4px;
    width: 4px;
    border: 0;
    background: #333;
    left: 50%;
    top: 50%;
    margin: -2px 0 0 -2px;
    -webkit-transition: all .1s ease-in-out;
    -moz-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
}

#fp-nav ul li:hover a span, .fp-slidesNav ul li:hover a span {
    width: 10px;
    height: 10px;
    margin: -5px 0 0 -5px;
}

#fp-nav ul li .fp-tooltip {
    position: absolute;
    top: -2px;
    color: #fff;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
    white-space: nowrap;
    max-width: 220px;
    overflow: hidden;
    display: block;
    opacity: 0;
    width: 0;
    cursor: pointer;
}

#fp-nav ul li:hover .fp-tooltip, #fp-nav.fp-show-active a.active+.fp-tooltip {
    -webkit-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
    width: auto;
    opacity: 1;
}

#fp-nav ul li .fp-tooltip.fp-right {
    right: 20px;
}

#fp-nav ul li .fp-tooltip.fp-left {
    left: 20px;
}

.fp-auto-height .fp-slide, .fp-auto-height .fp-tableCell, .fp-auto-height.fp-section {
    height: auto!important;
}

.fp-responsive .fp-auto-height-responsive .fp-slide, .fp-responsive .fp-auto-height-responsive .fp-tableCell, .fp-responsive .fp-auto-height-responsive.fp-section {
    height: auto!important;
}

.fp-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
    transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
    transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
    transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
    transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
    transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
    transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
    transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
    transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
    transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
    transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
    transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
    transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
    transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
    transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
    transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
    transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
    transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
    transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
    transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
    transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
    transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
    transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
    transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
    transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
    transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
    transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
    transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
    transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
    transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
    transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
    transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
    transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
    transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
    transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
    transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
    transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
    transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
    transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
    transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
    transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
    transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
    transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
    transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
    transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
    transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
    transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
    transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
    transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
    transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
    transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
    transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
    transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
    transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
    transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
    transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
    transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
    transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
    transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
    transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
    transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
    transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
    transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
    transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
    transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
    transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
    transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
    transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
    transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
    transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
    transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
    transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
    transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
    transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
    transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
    transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
    transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
    transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
    transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
    transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
    transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
    transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
    transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
    transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
    transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
    transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
    transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
    transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
    transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
    transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
    transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
    transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
    transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
    transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
    transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
    transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
    transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
    transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
    transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
    transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
    transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
    transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
    transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
    transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
    transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
    transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
    transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
    transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
    transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
    transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
    transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
    transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
    transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
    transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
    transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
    transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
    transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
    transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
    transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
    transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
    transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
    transition-delay: 3s;
}

[data-aos] {
    pointer-events: none;
}

[data-aos].aos-animate {
    pointer-events: auto;
}

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
    transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
    transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
    transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
    transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
    transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
    transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
    transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

@media screen {

    html:not(.no-js) [data-aos^=fade][data-aos^=fade] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}

html:not(.no-js) [data-aos^=fade][data-aos^=fade].aos-animate {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
}

html:not(.no-js) [data-aos=fade-up] {
    -webkit-transform: translate3d(0, 100px, 0);
    transform: translate3d(0, 100px, 0);
}

html:not(.no-js) [data-aos=fade-down] {
    -webkit-transform: translate3d(0, -100px, 0);
    transform: translate3d(0, -100px, 0);
}

html:not(.no-js) [data-aos=fade-right] {
    -webkit-transform: translate3d(-100px, 0, 0);
    transform: translate3d(-100px, 0, 0);
}

html:not(.no-js) [data-aos=fade-left] {
    -webkit-transform: translate3d(100px, 0, 0);
    transform: translate3d(100px, 0, 0);
}

html:not(.no-js) [data-aos=fade-up-right] {
    -webkit-transform: translate3d(-100px, 100px, 0);
    transform: translate3d(-100px, 100px, 0);
}

html:not(.no-js) [data-aos=fade-up-left] {
    -webkit-transform: translate3d(100px, 100px, 0);
    transform: translate3d(100px, 100px, 0);
}

html:not(.no-js) [data-aos=fade-down-right] {
    -webkit-transform: translate3d(-100px, -100px, 0);
    transform: translate3d(-100px, -100px, 0);
}

html:not(.no-js) [data-aos=fade-down-left] {
    -webkit-transform: translate3d(100px, -100px, 0);
    transform: translate3d(100px, -100px, 0);
}

html:not(.no-js) [data-aos^=zoom][data-aos^=zoom] {
    opacity: 0;
    transition-property: opacity, -webkit-transform;
    transition-property: opacity, transform;
    transition-property: opacity, transform, -webkit-transform;
}

html:not(.no-js) [data-aos^=zoom][data-aos^=zoom].aos-animate {
    opacity: 1;
    -webkit-transform: translateZ(0) scale(1);
    transform: translateZ(0) scale(1);
}

html:not(.no-js) [data-aos=zoom-in] {
    -webkit-transform: scale(.6);
    transform: scale(.6);
}

html:not(.no-js) [data-aos=zoom-in-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(.6);
    transform: translate3d(0, 100px, 0) scale(.6);
}

html:not(.no-js) [data-aos=zoom-in-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(.6);
    transform: translate3d(0, -100px, 0) scale(.6);
}

html:not(.no-js) [data-aos=zoom-in-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(.6);
    transform: translate3d(-100px, 0, 0) scale(.6);
}

html:not(.no-js) [data-aos=zoom-in-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(.6);
    transform: translate3d(100px, 0, 0) scale(.6);
}

html:not(.no-js) [data-aos=zoom-out] {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

html:not(.no-js) [data-aos=zoom-out-up] {
    -webkit-transform: translate3d(0, 100px, 0) scale(1.2);
    transform: translate3d(0, 100px, 0) scale(1.2);
}

html:not(.no-js) [data-aos=zoom-out-down] {
    -webkit-transform: translate3d(0, -100px, 0) scale(1.2);
    transform: translate3d(0, -100px, 0) scale(1.2);
}

html:not(.no-js) [data-aos=zoom-out-right] {
    -webkit-transform: translate3d(-100px, 0, 0) scale(1.2);
    transform: translate3d(-100px, 0, 0) scale(1.2);
}

html:not(.no-js) [data-aos=zoom-out-left] {
    -webkit-transform: translate3d(100px, 0, 0) scale(1.2);
    transform: translate3d(100px, 0, 0) scale(1.2);
}

html:not(.no-js) [data-aos^=slide][data-aos^=slide] {
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    visibility: hidden;
}

html:not(.no-js) [data-aos^=slide][data-aos^=slide].aos-animate {
    visibility: visible;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

html:not(.no-js) [data-aos=slide-up] {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
}

html:not(.no-js) [data-aos=slide-down] {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
}

html:not(.no-js) [data-aos=slide-right] {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

html:not(.no-js) [data-aos=slide-left] {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

html:not(.no-js) [data-aos^=flip][data-aos^=flip] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
}

html:not(.no-js) [data-aos=flip-left] {
    -webkit-transform: perspective(2500px) rotateY(-100deg);
    transform: perspective(2500px) rotateY(-100deg);
}

html:not(.no-js) [data-aos=flip-left].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
}

html:not(.no-js) [data-aos=flip-right] {
    -webkit-transform: perspective(2500px) rotateY(100deg);
    transform: perspective(2500px) rotateY(100deg);
}

html:not(.no-js) [data-aos=flip-right].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
}

html:not(.no-js) [data-aos=flip-up] {
    -webkit-transform: perspective(2500px) rotateX(-100deg);
    transform: perspective(2500px) rotateX(-100deg);
}

html:not(.no-js) [data-aos=flip-up].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
}

html:not(.no-js) [data-aos=flip-down] {
    -webkit-transform: perspective(2500px) rotateX(100deg);
    transform: perspective(2500px) rotateX(100deg);
}

html:not(.no-js) [data-aos=flip-down].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
}

}

@font-face {
    font-family: GothamPro;
    font-weight: 400;
    font-style: normal;
    src: url(../fonts/GothamPro/GothamPro.eot);
    src: url(../fonts/GothamPro/GothamPro.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamPro/GothamPro.woff) format("woff"), url(../fonts/GothamPro/GothamPro.ttf) format("truetype");
}

@font-face {
    font-family: GothamPro-Bold;
    font-weight: 700;
    font-style: normal;
    src: url(../fonts/GothamPro-Bold/GothamPro-Bold.eot);
    src: url(../fonts/GothamPro-Bold/GothamPro-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/GothamPro-Bold/GothamPro-Bold.woff) format("woff"), url(../fonts/GothamPro-Bold/GothamPro-Bold.ttf) format("truetype");
}

@font-face {
    font-family: lg;
    font-weight: 400;
    font-style: normal;
    src: url(../fonts/lg/lg.eot);
    src: url(../fonts/lg/lg.eot?#iefix) format("embedded-opentype"), url(../fonts/lg/lg.woff) format("woff"), url(../fonts/lg/lg.ttf) format("truetype");
}

*, ::after, ::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
    width: device-width;
}

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus {
    outline: 0!important;
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[data-original-title], abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b, strong {
    font-weight: bolder;
}

small {
    font-size: 80%
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button, input {
    overflow: visible;
}

button, select {
    text-transform: none;
}

[type=reset], [type=submit], button, html [type=button] {
    -webkit-appearance: button;
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=checkbox], input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none!important;
}

@-ms-viewport {
    width: device-width;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

*, ::after, ::before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

.container {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container {
    max-width: 540px;
}
}

@media (min-width:768px) {
    .container {
    max-width: 620px;
}
}

@media (min-width:992px) {
    .container {
    max-width: 700px;
}
}

@media (min-width:1200px) {
    .container {
    max-width: 980px;
}
}

.container-fluid {
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col, .no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

.col {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.col-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}

.order-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
}

.order-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}

.order-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}

.order-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
}

.order-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}

.order-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
}

.order-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
}

.order-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
}

.order-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
}

.order-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
}

.order-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
}

.order-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
}

.order-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
}

.order-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width:576px) {
    .col-sm {
        -webkit-flex-basis: 0;
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

.col-sm-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}
.col-sm-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%}
.col-sm-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%}
.col-sm-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%}
.col-sm-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%}
.col-sm-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%}
.col-sm-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%}
.col-sm-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%}
.col-sm-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%}
.col-sm-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%}
.col-sm-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%}
.col-sm-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%}
.col-sm-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%}
.order-sm-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}
.order-sm-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
}
.order-sm-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}
.order-sm-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}
.order-sm-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
}
.order-sm-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}
.order-sm-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
}
.order-sm-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
}
.order-sm-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
}
.order-sm-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
}
.order-sm-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
}
.order-sm-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
}
.order-sm-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
}
.order-sm-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
}
.order-sm-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
}
.offset-sm-0 {
    margin-left: 0;
}
.offset-sm-1 {
    margin-left: 8.33333%}
.offset-sm-2 {
    margin-left: 16.66667%}
.offset-sm-3 {
    margin-left: 25%}
.offset-sm-4 {
    margin-left: 33.33333%}
.offset-sm-5 {
    margin-left: 41.66667%}
.offset-sm-6 {
    margin-left: 50%}
.offset-sm-7 {
    margin-left: 58.33333%}
.offset-sm-8 {
    margin-left: 66.66667%}
.offset-sm-9 {
    margin-left: 75%}
.offset-sm-10 {
    margin-left: 83.33333%}
.offset-sm-11 {
    margin-left: 91.66667%}
}@media (min-width:768px) {
    .col-md {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%}
.col-md-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}
.col-md-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%}
.col-md-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%}
.col-md-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%}
.col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%}
.col-md-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%}
.col-md-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%}
.col-md-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%}
.col-md-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%}
.col-md-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%}
.col-md-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%}
.col-md-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%}
.col-md-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%}
.order-md-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}
.order-md-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
}
.order-md-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}
.order-md-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}
.order-md-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
}
.order-md-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}
.order-md-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
}
.order-md-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
}
.order-md-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
}
.order-md-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
}
.order-md-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
}
.order-md-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
}
.order-md-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
}
.order-md-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
}
.order-md-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
}
.offset-md-0 {
    margin-left: 0;
}
.offset-md-1 {
    margin-left: 8.33333%}
.offset-md-2 {
    margin-left: 16.66667%}
.offset-md-3 {
    margin-left: 25%}
.offset-md-4 {
    margin-left: 33.33333%}
.offset-md-5 {
    margin-left: 41.66667%}
.offset-md-6 {
    margin-left: 50%}
.offset-md-7 {
    margin-left: 58.33333%}
.offset-md-8 {
    margin-left: 66.66667%}
.offset-md-9 {
    margin-left: 75%}
.offset-md-10 {
    margin-left: 83.33333%}
.offset-md-11 {
    margin-left: 91.66667%}
}@media (min-width:992px) {
    .col-lg {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%}
.col-lg-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}
.col-lg-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%}
.col-lg-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%}
.col-lg-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%}
.col-lg-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%}
.col-lg-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%}
.col-lg-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%}
.col-lg-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%}
.col-lg-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%}
.col-lg-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%}
.col-lg-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%}
.col-lg-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%}
.col-lg-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%}
.order-lg-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}
.order-lg-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
}
.order-lg-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}
.order-lg-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}
.order-lg-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
}
.order-lg-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}
.order-lg-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
}
.order-lg-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
}
.order-lg-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
}
.order-lg-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
}
.order-lg-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
}
.order-lg-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
}
.order-lg-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
}
.order-lg-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
}
.order-lg-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
}
.offset-lg-0 {
    margin-left: 0;
}
.offset-lg-1 {
    margin-left: 8.33333%}
.offset-lg-2 {
    margin-left: 16.66667%}
.offset-lg-3 {
    margin-left: 25%}
.offset-lg-4 {
    margin-left: 33.33333%}
.offset-lg-5 {
    margin-left: 41.66667%}
.offset-lg-6 {
    margin-left: 50%}
.offset-lg-7 {
    margin-left: 58.33333%}
.offset-lg-8 {
    margin-left: 66.66667%}
.offset-lg-9 {
    margin-left: 75%}
.offset-lg-10 {
    margin-left: 83.33333%}
.offset-lg-11 {
    margin-left: 91.66667%}
}@media (min-width:1200px) {
    .col-xl {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%}
.col-xl-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}
.col-xl-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%}
.col-xl-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%}
.col-xl-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%}
.col-xl-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%}
.col-xl-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%}
.col-xl-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%}
.col-xl-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%}
.col-xl-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%}
.col-xl-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%}
.col-xl-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%}
.col-xl-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%}
.col-xl-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%}
.order-xl-first {
    -webkit-box-ordinal-group: 0;
    -webkit-order: -1;
    -ms-flex-order: -1;
    order: -1;
}
.order-xl-last {
    -webkit-box-ordinal-group: 14;
    -webkit-order: 13;
    -ms-flex-order: 13;
    order: 13;
}
.order-xl-0 {
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
}
.order-xl-1 {
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}
.order-xl-2 {
    -webkit-box-ordinal-group: 3;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
}
.order-xl-3 {
    -webkit-box-ordinal-group: 4;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
}
.order-xl-4 {
    -webkit-box-ordinal-group: 5;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
}
.order-xl-5 {
    -webkit-box-ordinal-group: 6;
    -webkit-order: 5;
    -ms-flex-order: 5;
    order: 5;
}
.order-xl-6 {
    -webkit-box-ordinal-group: 7;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
}
.order-xl-7 {
    -webkit-box-ordinal-group: 8;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
}
.order-xl-8 {
    -webkit-box-ordinal-group: 9;
    -webkit-order: 8;
    -ms-flex-order: 8;
    order: 8;
}
.order-xl-9 {
    -webkit-box-ordinal-group: 10;
    -webkit-order: 9;
    -ms-flex-order: 9;
    order: 9;
}
.order-xl-10 {
    -webkit-box-ordinal-group: 11;
    -webkit-order: 10;
    -ms-flex-order: 10;
    order: 10;
}
.order-xl-11 {
    -webkit-box-ordinal-group: 12;
    -webkit-order: 11;
    -ms-flex-order: 11;
    order: 11;
}
.order-xl-12 {
    -webkit-box-ordinal-group: 13;
    -webkit-order: 12;
    -ms-flex-order: 12;
    order: 12;
}
.offset-xl-0 {
    margin-left: 0;
}
.offset-xl-1 {
    margin-left: 8.33333%}
.offset-xl-2 {
    margin-left: 16.66667%}
.offset-xl-3 {
    margin-left: 25%}
.offset-xl-4 {
    margin-left: 33.33333%}
.offset-xl-5 {
    margin-left: 41.66667%}
.offset-xl-6 {
    margin-left: 50%}
.offset-xl-7 {
    margin-left: 58.33333%}
.offset-xl-8 {
    margin-left: 66.66667%}
.offset-xl-9 {
    margin-left: 75%}
.offset-xl-10 {
    margin-left: 83.33333%}
.offset-xl-11 {
    margin-left: 91.66667%}
}.d-none {
    display: none!important;
}
.d-inline {
    display: inline!important;
}
.d-inline-block {
    display: inline-block!important;
}
.d-block {
    display: block!important;
}
.d-table {
    display: table!important;
}
.d-table-row {
    display: table-row!important;
}
.d-table-cell {
    display: table-cell!important;
}
.d-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.d-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
}
@media (min-width:576px) {
    .d-sm-none {
    display: none!important;
}
.d-sm-inline {
    display: inline!important;
}
.d-sm-inline-block {
    display: inline-block!important;
}
.d-sm-block {
    display: block!important;
}
.d-sm-table {
    display: table!important;
}
.d-sm-table-row {
    display: table-row!important;
}
.d-sm-table-cell {
    display: table-cell!important;
}
.d-sm-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.d-sm-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
}
}@media (min-width:768px) {
    .d-md-none {
    display: none!important;
}
.d-md-inline {
    display: inline!important;
}
.d-md-inline-block {
    display: inline-block!important;
}
.d-md-block {
    display: block!important;
}
.d-md-table {
    display: table!important;
}
.d-md-table-row {
    display: table-row!important;
}
.d-md-table-cell {
    display: table-cell!important;
}
.d-md-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.d-md-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
}
}@media (min-width:992px) {
    .d-lg-none {
    display: none!important;
}
.d-lg-inline {
    display: inline!important;
}
.d-lg-inline-block {
    display: inline-block!important;
}
.d-lg-block {
    display: block!important;
}
.d-lg-table {
    display: table!important;
}
.d-lg-table-row {
    display: table-row!important;
}
.d-lg-table-cell {
    display: table-cell!important;
}
.d-lg-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.d-lg-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
}
}@media (min-width:1200px) {
    .d-xl-none {
    display: none!important;
}
.d-xl-inline {
    display: inline!important;
}
.d-xl-inline-block {
    display: inline-block!important;
}
.d-xl-block {
    display: block!important;
}
.d-xl-table {
    display: table!important;
}
.d-xl-table-row {
    display: table-row!important;
}
.d-xl-table-cell {
    display: table-cell!important;
}
.d-xl-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.d-xl-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
}
}@media print {
    .d-print-none {
    display: none!important;
}
.d-print-inline {
    display: inline!important;
}
.d-print-inline-block {
    display: inline-block!important;
}
.d-print-block {
    display: block!important;
}
.d-print-table {
    display: table!important;
}
.d-print-table-row {
    display: table-row!important;
}
.d-print-table-cell {
    display: table-cell!important;
}
.d-print-flex {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
}
.d-print-inline-flex {
    display: -webkit-inline-box!important;
    display: -webkit-inline-flex!important;
    display: -ms-inline-flexbox!important;
    display: inline-flex!important;
}
}.flex-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
}
.flex-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}
.flex-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
}
.flex-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important;
}
.flex-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
}
.flex-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
}
.flex-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important;
}
.justify-content-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important;
}
.justify-content-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important;
}
.justify-content-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.justify-content-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}
.justify-content-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important;
}
.align-items-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important;
}
.align-items-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important;
}
.align-items-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}
.align-items-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important;
}
.align-items-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important;
}
.align-content-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important;
}
.align-content-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important;
}
.align-content-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important;
}
.align-content-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important;
}
.align-content-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important;
}
.align-content-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important;
}
.align-self-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important;
}
.align-self-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important;
}
.align-self-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important;
}
.align-self-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important;
}
.align-self-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important;
}
.align-self-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important;
}
@media (min-width:576px) {
    .flex-sm-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
}
.flex-sm-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}
.flex-sm-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
}
.flex-sm-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important;
}
.flex-sm-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
}
.flex-sm-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
}
.flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important;
}
.justify-content-sm-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important;
}
.justify-content-sm-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important;
}
.justify-content-sm-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.justify-content-sm-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}
.justify-content-sm-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important;
}
.align-items-sm-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important;
}
.align-items-sm-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important;
}
.align-items-sm-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}
.align-items-sm-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important;
}
.align-items-sm-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important;
}
.align-content-sm-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important;
}
.align-content-sm-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important;
}
.align-content-sm-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important;
}
.align-content-sm-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important;
}
.align-content-sm-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important;
}
.align-content-sm-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important;
}
.align-self-sm-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important;
}
.align-self-sm-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important;
}
.align-self-sm-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important;
}
.align-self-sm-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important;
}
.align-self-sm-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important;
}
.align-self-sm-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important;
}
}@media (min-width:768px) {
    .flex-md-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
}
.flex-md-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}
.flex-md-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
}
.flex-md-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important;
}
.flex-md-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
}
.flex-md-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
}
.flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important;
}
.justify-content-md-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important;
}
.justify-content-md-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important;
}
.justify-content-md-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.justify-content-md-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}
.justify-content-md-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important;
}
.align-items-md-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important;
}
.align-items-md-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important;
}
.align-items-md-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}
.align-items-md-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important;
}
.align-items-md-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important;
}
.align-content-md-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important;
}
.align-content-md-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important;
}
.align-content-md-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important;
}
.align-content-md-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important;
}
.align-content-md-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important;
}
.align-content-md-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important;
}
.align-self-md-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important;
}
.align-self-md-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important;
}
.align-self-md-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important;
}
.align-self-md-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important;
}
.align-self-md-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important;
}
.align-self-md-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important;
}
}@media (min-width:992px) {
    .flex-lg-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
}
.flex-lg-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}
.flex-lg-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
}
.flex-lg-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important;
}
.flex-lg-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
}
.flex-lg-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
}
.flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important;
}
.justify-content-lg-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important;
}
.justify-content-lg-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important;
}
.justify-content-lg-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.justify-content-lg-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}
.justify-content-lg-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important;
}
.align-items-lg-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important;
}
.align-items-lg-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important;
}
.align-items-lg-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}
.align-items-lg-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important;
}
.align-items-lg-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important;
}
.align-content-lg-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important;
}
.align-content-lg-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important;
}
.align-content-lg-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important;
}
.align-content-lg-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important;
}
.align-content-lg-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important;
}
.align-content-lg-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important;
}
.align-self-lg-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important;
}
.align-self-lg-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important;
}
.align-self-lg-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important;
}
.align-self-lg-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important;
}
.align-self-lg-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important;
}
.align-self-lg-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important;
}
}@media (min-width:1200px) {
    .flex-xl-row {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: row!important;
    -ms-flex-direction: row!important;
    flex-direction: row!important;
}
.flex-xl-column {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: normal!important;
    -webkit-flex-direction: column!important;
    -ms-flex-direction: column!important;
    flex-direction: column!important;
}
.flex-xl-row-reverse {
    -webkit-box-orient: horizontal!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: row-reverse!important;
    -ms-flex-direction: row-reverse!important;
    flex-direction: row-reverse!important;
}
.flex-xl-column-reverse {
    -webkit-box-orient: vertical!important;
    -webkit-box-direction: reverse!important;
    -webkit-flex-direction: column-reverse!important;
    -ms-flex-direction: column-reverse!important;
    flex-direction: column-reverse!important;
}
.flex-xl-wrap {
    -webkit-flex-wrap: wrap!important;
    -ms-flex-wrap: wrap!important;
    flex-wrap: wrap!important;
}
.flex-xl-nowrap {
    -webkit-flex-wrap: nowrap!important;
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
}
.flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse!important;
    -ms-flex-wrap: wrap-reverse!important;
    flex-wrap: wrap-reverse!important;
}
.justify-content-xl-start {
    -webkit-box-pack: start!important;
    -webkit-justify-content: flex-start!important;
    -ms-flex-pack: start!important;
    justify-content: flex-start!important;
}
.justify-content-xl-end {
    -webkit-box-pack: end!important;
    -webkit-justify-content: flex-end!important;
    -ms-flex-pack: end!important;
    justify-content: flex-end!important;
}
.justify-content-xl-center {
    -webkit-box-pack: center!important;
    -webkit-justify-content: center!important;
    -ms-flex-pack: center!important;
    justify-content: center!important;
}
.justify-content-xl-between {
    -webkit-box-pack: justify!important;
    -webkit-justify-content: space-between!important;
    -ms-flex-pack: justify!important;
    justify-content: space-between!important;
}
.justify-content-xl-around {
    -webkit-justify-content: space-around!important;
    -ms-flex-pack: distribute!important;
    justify-content: space-around!important;
}
.align-items-xl-start {
    -webkit-box-align: start!important;
    -webkit-align-items: flex-start!important;
    -ms-flex-align: start!important;
    align-items: flex-start!important;
}
.align-items-xl-end {
    -webkit-box-align: end!important;
    -webkit-align-items: flex-end!important;
    -ms-flex-align: end!important;
    align-items: flex-end!important;
}
.align-items-xl-center {
    -webkit-box-align: center!important;
    -webkit-align-items: center!important;
    -ms-flex-align: center!important;
    align-items: center!important;
}
.align-items-xl-baseline {
    -webkit-box-align: baseline!important;
    -webkit-align-items: baseline!important;
    -ms-flex-align: baseline!important;
    align-items: baseline!important;
}
.align-items-xl-stretch {
    -webkit-box-align: stretch!important;
    -webkit-align-items: stretch!important;
    -ms-flex-align: stretch!important;
    align-items: stretch!important;
}
.align-content-xl-start {
    -webkit-align-content: flex-start!important;
    -ms-flex-line-pack: start!important;
    align-content: flex-start!important;
}
.align-content-xl-end {
    -webkit-align-content: flex-end!important;
    -ms-flex-line-pack: end!important;
    align-content: flex-end!important;
}
.align-content-xl-center {
    -webkit-align-content: center!important;
    -ms-flex-line-pack: center!important;
    align-content: center!important;
}
.align-content-xl-between {
    -webkit-align-content: space-between!important;
    -ms-flex-line-pack: justify!important;
    align-content: space-between!important;
}
.align-content-xl-around {
    -webkit-align-content: space-around!important;
    -ms-flex-line-pack: distribute!important;
    align-content: space-around!important;
}
.align-content-xl-stretch {
    -webkit-align-content: stretch!important;
    -ms-flex-line-pack: stretch!important;
    align-content: stretch!important;
}
.align-self-xl-auto {
    -webkit-align-self: auto!important;
    -ms-flex-item-align: auto!important;
    align-self: auto!important;
}
.align-self-xl-start {
    -webkit-align-self: flex-start!important;
    -ms-flex-item-align: start!important;
    align-self: flex-start!important;
}
.align-self-xl-end {
    -webkit-align-self: flex-end!important;
    -ms-flex-item-align: end!important;
    align-self: flex-end!important;
}
.align-self-xl-center {
    -webkit-align-self: center!important;
    -ms-flex-item-align: center!important;
    align-self: center!important;
}
.align-self-xl-baseline {
    -webkit-align-self: baseline!important;
    -ms-flex-item-align: baseline!important;
    align-self: baseline!important;
}
.align-self-xl-stretch {
    -webkit-align-self: stretch!important;
    -ms-flex-item-align: stretch!important;
    align-self: stretch!important;
}
}.navbar {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: .5rem 1rem;
}
.navbar>.container, .navbar>.container-fluid {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}
.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}
.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none;
}
.navbar-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}
.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
}
.navbar-collapse {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}
.navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%}
@media (max-width:575.98px) {
    .navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}
}@media (min-width:576px) {
    .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}
.navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand-sm>.container, .navbar-expand-sm>.container-fluid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.navbar-expand-sm .navbar-collapse {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}
.navbar-expand-sm .navbar-toggler {
    display: none;
}
.navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%}
}@media (max-width:767.98px) {
    .navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}
}@media (min-width:768px) {
    .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}
.navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand-md>.container, .navbar-expand-md>.container-fluid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.navbar-expand-md .navbar-collapse {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}
.navbar-expand-md .navbar-toggler {
    display: none;
}
.navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%}
}@media (max-width:991.98px) {
    .navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}
}@media (min-width:992px) {
    .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.navbar-expand-lg .navbar-collapse {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}
.navbar-expand-lg .navbar-toggler {
    display: none;
}
.navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%}
}@media (max-width:1199.98px) {
    .navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}
}@media (min-width:1200px) {
    .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}
.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand-xl>.container, .navbar-expand-xl>.container-fluid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.navbar-expand-xl .navbar-collapse {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}
.navbar-expand-xl .navbar-toggler {
    display: none;
}
.navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%}
}.navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}
.navbar-expand>.container, .navbar-expand>.container-fluid {
    padding-right: 0;
    padding-left: 0;
}
.navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}
.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}
.navbar-expand>.container, .navbar-expand>.container-fluid {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
    display: -webkit-box!important;
    display: -webkit-flex!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
}
.navbar-expand .navbar-toggler {
    display: none;
}
.navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%}
.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7);
}
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3);
}
.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1);
}
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 30 30' xmlns='http: //www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,  0,  0,  0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5);
}
.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, .9);
}
.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9);
}
.navbar-dark .navbar-brand {
    color: #fff;
}
.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, .5);
}
.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, .75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, .25);
}
.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
    color: #fff;
}
.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, .5);
    border-color: rgba(255, 255, 255, .1);
}
.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8, %3Csvg viewBox='0 0 30 30' xmlns='http: //www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,  255,  255,  0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, .5);
}
.navbar-dark .navbar-text a {
    color: #fff;
}
.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
    color: #fff;
}
.nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.nav-link {
    display: block;
    padding: .5rem 1rem;
}
.nav-link:focus, .nav-link:hover {
    text-decoration: none;
}
.nav-link.disabled {
    color: #6c757d;
}
.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
    margin-bottom: -1px;
}
.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.nav-pills .nav-link {
    border-radius: .25rem;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}
.nav-fill .nav-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center;
}
.nav-justified .nav-item {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center;
}
.tab-content>.tab-pane {
    display: none;
}
.tab-content>.active {
    display: block;
}
::-webkit-input-placeholder {
    color: #666;
}
::-moz-placeholder {
    color: #666;
}
:-ms-input-placeholder {
    color: #666;
}
::-ms-input-placeholder {
    color: #666;
}
::placeholder {
    color: #666;
}
::-moz-selection {
    background-color: #00f;
    color: #fff;
}
::selection {
    background-color: #00f;
    color: #fff;
}
body {
    background-color: #d7d7d7;
    font-size: 16px;
    min-width: 320px;
    position: relative;
    line-height: 1.65;
    font-family: GothamPro, sans-serif;
    overflow-x: hidden;
    color: #262424;
}
body input, body textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: #666 1px solid;
    outline: 0;
}
body input:focus:required:invalid, body textarea:focus:required:invalid {
    border-color: red;
}
body input:required:valid, body textarea:required:valid {
    border-color: green;
}
body input.error, body textarea.error {
    background-color: rgba(246, 71, 71, .1);
    border: 1px solid #f64747;
}
.row-flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}
.img-responsive.img-width {
    width: 100%}
.text-center {
    text-align: center;
}
b, strong {
    font-weight: 700;
}
.button {
    min-width: 210px;
    text-align: center;
    background-color: #543790;
    color: #fff;
    font-size: 11px;
    font-family: GothamPro-Bold, sans-serif;
    text-transform: uppercase;
    letter-spacing: .2em;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 50px;
    -webkit-transition: .2s all;
    -o-transition: .2s all;
    transition: .2s all;
    cursor: pointer;
    z-index: 5;
}
.button:hover {
    -webkit-box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
    box-shadow: 0 10px 20px rgba(84, 55, 144, .2);
}
button {
    outline: 0!important;
    border: none!important;
}
.between_block {
    height: 100px;
    width: 100%}
h2 {
    font-family: GothamPro-Bold, sans-serif;
    text-transform: uppercase;
    font-size: 40px;
    margin-bottom: 40px;
}
h3 {
    font-family: GothamPro-Bold, sans-serif;
    color: #543790;
    font-size: 20px;
    margin-bottom: 20px;
}
h3 span {
    white-space: nowrap;
}
p {
    font-size: 20px;
}
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: #d7d7d7;
}
.preloader img {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 180px;
    -webkit-animation: pulse 1s infinite;
    animation: pulse 1s infinite;
}
@-webkit-keyframes pulse {
    0% {
    -webkit-transform: translate(-50%, -50%) scale(.95);
    transform: translate(-50%, -50%) scale(.95);
}
70% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}
100% {
    -webkit-transform: translate(-50%, -50%) scale(.95);
    transform: translate(-50%, -50%) scale(.95);
}
}@keyframes pulse {
    0% {
    -webkit-transform: translate(-50%, -50%) scale(.95);
    transform: translate(-50%, -50%) scale(.95);
}
70% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}
100% {
    -webkit-transform: translate(-50%, -50%) scale(.95);
    transform: translate(-50%, -50%) scale(.95);
}
}nav {
    background-color: #d7d7d7;
    padding-top: 20px!important;
    padding-bottom: 20px!important;
    position: fixed!important;
    z-index: 15;
    top: 0;
    left: 0;
    width: 100%;
    -webkit-transition: .2s all;
    -o-transition: .2s all;
    transition: .2s all;
    padding-top: 10px!important;
    padding-bottom: 10px!important;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}
nav img {
    max-width: 100px;
}
.item {
    position: relative;
}
.item p {
    min-height: 66px;
    padding: 10px 0 10px 60px;
    margin-bottom: 0;
}
.item p span {
    white-space: nowrap;
}
.item .icon {
    width: 50px;
    height: 50px;
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../img/accept.svg);
}
.item.i_01 .icon {
    background-image: url(../img/api.svg);
}
.item.i_02 .icon {
    background-image: url(../img/24-hours.svg);
}
.item.i_03 .icon {
    background-image: url(../img/sales.svg);
}
.mouse {
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    width: 40px;
    height: 40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 10;
}
.mouse::before {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-image: url(../img/mouse.svg);
    background-size: contain;
    -webkit-animation: mouse 1s infinite;
    animation: mouse 1s infinite;
    -webkit-transition: .2s all;
    -o-transition: .2s all;
    transition: .2s all;
}
.mouse .left, .mouse .right {
    display: block;
    content: "";
    position: absolute;
    height: 2px;
    background-color: #543790;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: .4;
}
.mouse:hover::before {
    -webkit-animation: none;
    animation: none;
    opacity: 1;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
}
@-webkit-keyframes mouse {
    0% {
    opacity: .7;
}
70% {
    opacity: .4;
}
100% {
    opacity: .7;
}
}@keyframes mouse {
    0% {
    opacity: .7;
}
70% {
    opacity: .4;
}
100% {
    opacity: .7;
}
}#block_1 .row {
    min-height: 85vh;
}
#block_1 p {
    font-size: 23px;
}
#block_1 p .title {
    display: block;
    color: #543790;
}
#block_1 p span {
    white-space: nowrap;
}
#block_1 .bg {
    background-position: right center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../img/mac_02.png);
    position: absolute;
    right: 0;
    top: 80px;
    width: 100%;
    height: 90%;
    z-index: -1;
}
#block_2 .intro {
    margin-bottom: 90px;
}
#block_3 h2 {
    font-size: 30px;
}
#block_3 .item::before {
    display: block;
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    background-color: #543790;
}
#block_3 .item .icon {
    display: none;
}
#block_3 .item p {
    min-height: auto;
    margin-bottom: 0;
    color: #262424;
    padding: 10px 0 10px 28px;
}
#block_4 img {
    max-width: 85%;
    margin: auto;
}
#block_5>div>div {
    margin-bottom: -40px;
}
#block_5 .col-custom {
    position: relative;
    margin-bottom: 40px;
}
#block_5 .col-custom::before {
    display: block;
    position: absolute;
    content: " ";
    left: 10px;
    right: 30px;
    top: 20px;
    height: 100%;
    border: 5px solid #543790;
    border-radius: 25px;
}
#block_5 .card {
    padding: 0 40px 0 20px;
    position: relative;
    border: none;
    background-color: inherit;
}
#block_5 .card .title {
    font-size: 14px;
    margin-bottom: 20px;
    margin-top: 3px;
    text-transform: uppercase;
    color: #543790;
    font-family: GothamPro-Bold, sans-serif;
    background-color: #d7d7d7;
    padding: 0 10px;
}
#block_5 .card .number {
    position: absolute;
    width: 44px;
    height: 44px;
    line-height: 36px;
    text-align: center;
    border: 4px solid #543790;
    background: #fff;
    font-family: GothamPro-Bold, sans-serif;
    border-radius: 30px;
    position: absolute;
    left: -19px;
    top: 46px;
}
#block_5 .card p {
    font-size: 13px;
    padding: 0 10px;
}
#block_5 .end {
    font-family: GothamPro-Bold, sans-serif;
    color: #543790;
    font-size: 16px;
    padding-right: 20px;
    line-height: 30px;
}
#block_5 .end span {
    line-height: 40px;
    font-size: 40px;
    color: #885385;
}
#block_5 img {
    padding-left: 33%}
#block_6 {
    background-color: #ccc;
}
#block_6 img {
    max-width: 100px;
    margin-bottom: 30px;
}
#block_6 span {
    font-size: 14px;
    opacity: .4;
}
#block_6 p {
    font-size: 14px;
    word-wrap: break-word;
}
#block_7 img {
    max-width: 100%;
    max-width: 220px;
    margin: auto;
    margin-bottom: 80px;
}
#block_7 .sep {
    width: 100%;
    height: 3px;
    background-color: #543790;
}
#block_7 h4 {
    margin-top: 40px;
    font-size: 60px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, .3);
    font-weight: 100;
}
footer {
    padding: 100px 0;
    background-color: #cfcfcf;
}
footer img {
    width: 180px;
    margin: auto;
    display: block;
    margin-bottom: 20px;
}
#popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    opacity: 0;
    display: none;
}
#popup .bg {
    background-color: rgba(216, 216, 216, .9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}
#popup .row {
    min-height: 100vh;
}

.cont {
    padding: 80px 50px;
    background-color: #fff;
    z-index: 2;
    position: relative;
}

@media (max-width: 767.98px){
    .cont {
        padding: 40px 20px;
    }

    .btn-circle {
        width: 36px;
        height: 36px;
    }
}

@media (max-width: 325px){
    .main-logo {
        display: none;
    }

    .form-inline {
        justify-content: center;
        width: 100%;
    }
} 

.cont .title {
    margin-bottom: 20px;
}

.cont .title span {
    font-size: 20px;
    text-transform: uppercase;
    color: #543790;
    font-family: GothamPro-Bold, sans-serif;
    display: block;
}

.cont input {
    display: block;
    height: 60px;
    width: 100%;
    padding-left: 30px;
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, .1);
    font-size: 14px;
}
.cont input::-webkit-input-placeholder {
    color: rgba(0, 0, 0, .4);
}
.cont input::-moz-placeholder {
    color: rgba(0, 0, 0, .4);
}
.cont input:-ms-input-placeholder {
    color: rgba(0, 0, 0, .4);
}
.cont input::-ms-input-placeholder {
    color: rgba(0, 0, 0, .4);
}
.cont input::placeholder {
    color: rgba(0, 0, 0, .4);
}
.cont .button {
    margin-top: 10px;
}
.cont .policy {
    margin-top: 10px;
    font-size: 12px;
    color: rgba(0, 0, 0, .4);
}

.modal-backdrop.show {
    opacity: .9;
}

.modal-backdrop {
    background-color: rgba(216,216,216);
}

.modal-content {
    background-color: inherit;
    border: none;
}

.modal-backdrop {
    background-color: rgba(216,216,216);
}

.thanks .cont {
    text-align: center;
    padding: 0;
    z-index: 2;
    background-color: inherit;
    position: relative;
}

.thanks .cont img {
    max-width: 180px;
    margin-bottom: 40px;
}

.thanks .cont p span {
    font-size: 40px;
    color: #543790;
    text-transform: uppercase;
    font-family: GothamPro-Bold,sans-serif;
}

#fp-nav ul li a span, .fp-slidesNav ul li a span {
    background-color: #543790 !important;
}
@media (max-width:767.98px) {
    section {
    padding: .5rem 1rem;
}
#fp-nav {
    display: none;
}
nav img {
    width: 60px;
}
.button {
    padding: 10px 20px;
    font-size: 10px;
    min-width: auto;
}
h2 {
    font-size: 20px;
    margin-bottom: 20px;
}
p {
    font-size: 14px;
}
.mouse {
    display: none;
}
.item .icon {
    width: 30px;
    height: 30px;
}
.item p {
    padding-left: 40px;
}
#block_1 {
    position: relative;
}
#block_1 .mouse {
    display: block;
    bottom: 30px;
}
#block_1 .row {
    min-height: 100vh;
    min-height: -webkit-calc(var(--vh, 1vh) * 100);
    min-height: calc(var(--vh, 1vh) * 100);
}
#block_1 .col-12 {
    text-align: center;
}
#block_1 p {
    font-size: 16px;
}
#block_1 .button {
    margin: auto;
    text-align: center;
}
#block_1 .bg {
    display: none;
}
#block_2 {
    margin-top: 60px;
}
#block_2 .intro {
    margin-bottom: 20px;
}
#block_2 img {
    display: none;
}
#block_3 {
    margin-top: 80px;
}
#block_3 h2 {
    font-size: 18px;
}
#block_3 .item p {
    padding: 5px 0 5px 16px;
}
#block_4 {
    margin-top: 80px;
}
#block_4 img {
    max-width: 100%}
#block_5 {
    margin-top: 80px;
}
#block_5 .container .row {
    margin-bottom: 80px;
}
#block_5 .col-custom::before {
    left: 15px;
    right: 15px;
}
#block_5 .col-custom .card {
    padding: 0 30px;
}
#block_5 .col-custom .card .title {
    min-height: 30px;
}
#block_5 .col-custom .card .number {
    left: -14px;
}
#block_5 .end {
    padding: 0 20px;
}
#block_6 {
    padding-top: 70px;
    padding-bottom: 50px;
}
#block_6 img {
    display: none;
}
#popup .bg {
    cursor: pointer;
}
#popup .app .cont {
    padding: 40px 20px;
}
#popup .app .cont .title span {
    font-size: 16px;
}
#popup .app .cont input {
    height: 50px;
    line-height: 50px;
    padding-left: 20px;
}
}